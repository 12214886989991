@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Barlow', 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f5fb !important;
}

code {
  font-family: 'Barlow', 'Inter', monospace;
}
